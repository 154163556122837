<template>
  <div class="c-app flex-row align-items-center bg-blue">
    <CContainer>
      <CRow class="justify-content-center" style="margin-top: 0px">
        <CCol lg="6" style="margin-top: 20px" v-if="nowLat" class="text-center text-white">
          <h1>ละติจูดปัจจุบัน: 
          <br/>{{nowLat}}</h1>
        </CCol>
        <CCol lg="6" style="margin-top: 20px" v-if="nowLng" class="text-center text-white">
          ลองจิจูดปัจจุบัน: 
          <br/>{{nowLng}}
        </CCol>
        <CCol md="12" style="margin-top: 20px">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <form id="formLogin" v-on:submit.prevent="login" method="POST">
                  <p class="text-muted">โปรแกรมลงเวลาปฏิบัติงานจากที่บ้าน</p>
                  <CRow>
                    <CCol col="12" class="text-center">
                      <CButtonGroup>
                        <CButton color="primary">Check In</CButton>
                        <CButton color="danger">Check Out</CButton>
                      </CButtonGroup>
                    </CCol>
                  </CRow>
                </form>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
    <CModal
      :show.sync="darkModal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="dark"
    >
      password หรือ username ไม่ถูกต้อง
      <template #header>
        <h6 class="modal-title">คำเตือน!!</h6>
        <CButtonClose @click="darkModal = false" class="text-white" />
      </template>
      <template #footer>
        <CButton @click="darkModal = false" color="danger">Discard</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  name: "Login",
  components: {},
  data() {
    return {
      
      darkModal: false,
      nowLat: null,
      nowLng:null
    };
  },
  mounted() {
    navigator.geolocation.getCurrentPosition(this.showPosition);
  },
  methods: {
    login() {
      this.uploadingStat = 1;
      const form = document.getElementById("formLogin");
      const formData = new FormData(form);
      formData.append("txtAction", "get");
      this.axios
        .post(this.$hostUrl + "php_action/userAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == true) {
            this.darkModal = true;
          } else {
            this.$cookies.set("user", response.data.userData);
            //this.$store.commit('setUserData', this.$cookies.get("user"));
            this.$router.push({ path: "../dashboard" });
          }
        })
        .finally(() => {});
    },
    showPosition(position){
      console.log(position.coords.latitude);
      console.log(position.coords.longitude);
      this.nowLat = position.coords.latitude;
      this.nowLng = position.coords.longitude;
    }
  },
};
</script>

<style scoped>
#container {
  margin: 0px auto;
  width: 100%;
  height: 375px;
  border: 10px #333 solid;
}

#videoElement {
  width: 100%;
  height: 375px;
  background-color: #666;
}
</style>
